import { DecoratorService } from '../../helpers/decorator-service.service';
import { ContentUpdaterService } from '../../services/content-updater/content-updater.service';
import { ReplaySubject } from 'rxjs';
import { delay } from 'rxjs/operators';

/**
 * Use this decorator when needs to automatically update content on lang change
 *
 * @param key
 * @constructor
 */
export function UpdateOnLangChange(key: string): any {
  return (target, propertyKey, descriptor) => {
    const originalMethod = descriptor.value;

    descriptor.value = function(...args: any[]) {
      const _updater: ContentUpdaterService = DecoratorService.injector.get(ContentUpdaterService);

      return _updater.addItem(key + JSON.stringify(args), {
        value: new ReplaySubject<any>(1).pipe(
          /**
           * Needs for templates re-rendering
           */
          delay(1)
        ) as ReplaySubject<any>,
        updateFn: originalMethod.bind(this, ...args)
      });
    };
  };
}
