import { Inject, Injectable } from '@angular/core';
import { LanguageService } from './language/language.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ArabicService {

  /**
   * ARABIC locales
   */
  public arLocales = ['qa', 'ar', 'kw', 'ae'];

  /**
   * Check is user select arabic lang
   */
  private _isAr = false;

  constructor(
    private _lang: LanguageService,
    @Inject(DOCUMENT) private _document: Document
  ) {
  }

  /**
   * Access to checking is user selected AR lang
   */
  public get isAr(): boolean {
    return this._isAr;
  }

  public resolveArabic() {
    this._lang.langChange$.subscribe(currentLanguage => {
      this._isAr = this.arLocales.some(locale => currentLanguage === locale);
    });
  }
}
